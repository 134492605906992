* {
    box-sizing: border-box;
}

html,
body,
#root {
    width: 100vw;
    height: calc(100 * var(--vh));
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.fc {
    display: flex;
    flex-direction: column;
}

.cc {
    justify-content: center;
    align-items: center;
}

.sc {
    justify-content: flex-start;
    align-items: center;
}

.fh {
    height: 100%;
}

.fh80 {
    height: 80%;
}

.fw {
    width: 100%;
}

.fw80 {
    width: 80%;
}

.scrolly {
    overflow-y: auto;
}
